/* ////////////////////////////////// INDEX /////////////////////////////// */


#cover {
    background-image: url("../../public/img/index/cover-accueil.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    animation: animeBackgroundLeft 40s alternate infinite;
}

#cover h2 {
    font-family: "freight-neo-pro", sans-serif;
    color: #fff;
    font-size: calc(40px + 25 * (100vw - 320px) / 1080);
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 50px;
}

#cover .ct {
    top: 50%;
    transform: translateY(-50%);
    max-width: 35%;
}


.section-ix {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    padding: 50px;
}


.section-ix:nth-child(n+1) {
    animation: animeBackgroundRight 40s alternate infinite;
}

.section-ix:nth-child(2n+1) {
   animation: animeBackgroundLeft 40s alternate infinite;
}

@keyframes animeBackgroundLeft {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}


@keyframes animeBackgroundRight {
    0% {
        background-position: right;
    }
    100% {
        background-position: left;
    }
}


.section-ix h2 {
    text-transform: uppercase;
    font-size: calc(30px + 15 * (100vw - 320px) / 1080);
    letter-spacing: 3px;
    font-weight: 300;
    margin-bottom: 50px;
}

.section-ix .bn {
    margin: 0 auto;
}

.section-ix .link-cover {
    background-color: rgba(24, 24, 26,0.25);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 98;
}

.section-ix .link-index {
    z-index: 99;
    color: #fff;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

#seins-ix {
    background-image: url("../../public/img/index/intervention-seins.jpg");
}

#ventre-ix {
    background-image: url("../../public/img/index/intervention-ventre.jpg");
}

#visage-ix {
    background-image: url("../../public/img/index/intervention-visage.jpg");
}

#medecine-ix {
    background-image: url("../../public/img/index/medecine-esthetique.jpg");
}


/* ////////////////////// MEDIA QUERIES //////////////////// */


/************* max 550 **************/ @media screen and (max-width:550px) {

    #cover .ct {
        max-width: 100%;
        text-align: center;
    }

    #cover .ct .bn.bn-white {
        margin: 0 auto;
    }

    #cover {
        animation: animeBackgroundLeft 40s alternate infinite;
    }


    #cover {
        background-image: url("../../public/img/index/cover-accueil-tel.jpg");
    }

    #seins-ix {
        background-image: url("../../public/img/index/intervention-seins-tel.jpg");
    }

    #ventre-ix {
        background-image: url("../../public/img/index/intervention-ventre-tel.jpg");
    }

    #visage-ix {
        background-image: url("../../public/img/index/intervention-visage-tel.jpg");
    }

    #medecine-ix {
        background-image: url("../../public/img/index/medecine-esthetique-tel.jpg");
    }

    .section-ix:nth-child(n+1) {
        animation: animeBackgroundRight 30s alternate infinite;
    }

    .section-ix:nth-child(2n+1) {
        animation: animeBackgroundLeft 30s alternate infinite;
    }

}